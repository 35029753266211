import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

// 告诉Vue使用VueRouter
Vue.use(VueRouter)

//   Vue实例代码
//   const routes = [
//   {
//     path: '/',
//     name: 'home',
//     component: HomeView
//   },
//   {
//     path: '/about',
//     name: 'about',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
//   }
// ]

const routes = [
    {
        path: '/home', // 首页路由
        name: 'home', // 路由名称
        meta: {
            title: '有米-公测（警告：投资有风险）'
        },
        component: () => import('@/views/HomeView.vue')
    },
    {
        path: '/AssetStructureOptimizer', // 资产结构股优化器路由
        name: 'asset-structure-optimizer', // 子路由名称
        meta: {
            title: '有米-我的资产结构（警告：投资有风险）'
        },
        component: () => import('@/views/pat-lab/AssetStructureOptimizer.vue')
    },
    // {
    //     path: 'MeanVarianceAnalyzer', // 资产结构股优化器路由
    //     name: 'mean-variance-analyzer', // 子路由名称
    //     component: () => import('@/views/MeanVarianceAnalyzer.vue')
    // },
    {
        path: '/PortfolioOptimizer', // 资产结构股优化器路由
        name: 'portfolio-optimizer', // 子路由名称
        meta: {
            title: '有米-我的投资组合（警告：投资有风险）',
            meta: [
                {
                  name: 'description',
                  content: '选择最优投资组合查看资产比例和收益预测'
                },
                {
                  property: 'og:title',
                  content: '投资组合优化器'
                },
                {
                  property: 'og:description',
                  content: '选择最优投资组合查看资产比例和收益预测'
                },
                {
                  property: 'og:image',
                  content: `${window.location.origin}/logopat.png`
                },
                {
                  property: 'og:url',
                  content: 'https://www.yomi.bot/pat-lab/myPortfolioVsAssetsComparison'
                },
                {
                  property: 'og:site_name',
                  content: '投资组合优化器'
                },
                {
                  property: 'og:type',
                  content: 'article'
                },
                {
                  name: 'weixin:description',
                  content: '选择最优投资组合查看资产比例和收益预测'
                },
                {
                  name: 'weixin:image',
                  content: `${window.location.origin}/logopat.png`
                }
              ]
        },
        component: () => import('@/views/PortfolioOptimizer.vue')
    },
    {
        path: '/pat-lab', // PAT Lab页面路由
        name: 'pat-Lab', // 路由名称
        component: () => import('@/views/PatLab.vue'), // 此处没有name属性，因为它有子路由
        children: [

            {
                path: 'myPortfolioVsAssetsComparison',
                name: 'myportfolio-vs-assets-comparison',
                meta: {
                    title: '我的投资组合收益',
                    meta: [
                        {
                          name: 'description',
                          content: '20年1月-24年9月收益：+55% VS 沪深300ETF： +7%'
                        },
                        {
                          property: 'og:title',
                          content: '我的投资组合收益  - 4年9个月：55%'
                        },
                        {
                          property: 'og:description',
                          content: '20年1月-24年9月收益：+55% VS 沪深300ETF： +7%'
                        },
                        {
                          property: 'og:image',
                          content: `${window.location.origin}/logopat.png`
                        },
                        {
                          property: 'og:url',
                          content: 'http://www.yomi.bot/#/pat-lab/myPortfolioVsAssetsComparison'
                        },
                        {
                          property: 'og:site_name',
                          content: '示例网站'
                        },
                        {
                          property: 'og:type',
                          content: 'article'
                        },
                        {
                          name: 'weixin:description',
                          content: '20年1月-24年9月收益：+55% VS 沪深300ETF： +7%'
                        },
                        {
                          name: 'weixin:image',
                          content: `${window.location.origin}/logopat.png`
                        }
                      ]
                },
                component: () => import('@/views/pat-lab/MyPortfolioVsAssetsComparison.vue')
            },

            // {
            //     path: 'AssetStructureOptimizer', // 资产结构股优化器路由
            //     name: 'asset-structure-optimizer', // 子路由名称
            //     component: () => import('@/views/pat-lab/AssetStructureOptimizer.vue')
            // },
            {
                path: 'MeanVarianceAnalyzer', // 资产结构股优化器路由
                name: 'mean-variance-analyzer', // 子路由名称
                meta: {
                    title: '有米-均值方法分析'
                },
                component: () => import('@/views/MeanVarianceAnalyzer.vue')
            },
            // {
            //     path: 'PortfolioOptimizer', // 资产结构股优化器路由
            //     name: 'portfolio-optimizer', // 子路由名称
            //     component: () => import('@/views/PortfolioOptimizer.vue')
            // },
            {
                path: 'M2GdpDisplay', // 资产结构股优化器路由
                name: 'm2-gdp-display', // 子路由名称
                meta: {
                    title: '有米-m2 VS gdp'
                },
                component: () => import('@/views/pat-lab/m2GdpDisplay.vue')
            }
        ]
    },
    {
        path: '/sandbox-practice',
        name: 'sandboxPractice', // 路由名称
        component: () => import('@/views/sandbox-practice/SandboxPractice.vue')
    },

    {
        path: '*', // 捕获所有不匹配的路由
        redirect: '/home' // 重定向到首页
    }
]
const router = new VueRouter({
    // mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'Default Title';
    next();
});


router.afterEach((to, from) => {
    Vue.nextTick(() => {
      document.title = to.meta.title || document.title;
      const metaTags = document.querySelectorAll('[data-vue-meta="true"]');
      metaTags.forEach(tag => tag.parentNode.removeChild(tag));
      if (to.meta.meta) {
        to.meta.meta.forEach(meta => {
          const tag = document.createElement('meta');
          Object.keys(meta).forEach(key => {
            tag.setAttribute(key, meta[key]);
          });
          tag.setAttribute('data-vue-meta', 'true');
          document.head.appendChild(tag);
        });
      }
    });
  });

export default router
